import axios from 'axios';

export const actionEnviarEmail = (data) => {

    var config = {
        method: 'post',
        url: 'https://api.katuxa.com.br/api/v1/publico/site/email/enviar',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };

    return axios(config)
}
