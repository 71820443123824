import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState } from "react";
import { maskCPF, formataNumeroCelular, formataNumeroFixo, maskCEP } from './utils/functions'
import { actionEnviarEmail } from './actions/actionEnviarEmail';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AddressForm() {

    const [cpf, setCpf] = useState("");
    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [fixo, setFixo] = useState("");
    const [celular, setCelular] = useState("");
    const [cep, setCep] = useState("");
    const [rua, setRua] = useState("");
    const [cidade, setCidade] = useState("");
    const [numero, setNumero] = useState("");
    const [complemento, setComplemento] = useState("");
    const [bairro, setBairro] = useState("");
    const [estado, setEstado] = useState("");
    const [local, setLocal] = useState("");
    const [mensagem, setMensagem] = useState("");

    const onEnviar = async () => {
        const data = JSON.stringify({
            "from": "franqueado@vkholding.com.br",
            "to": "contato@katuxa.com.br, rani@vkholding.com.br",
            "subject": `Solicitação de abertura de franquia feita por: ${nome}`,
            "html": `Olá, </strong> <i>${nome}</i> acaba de preencher o formulario de interesse em ser um franqueado.<br>
            <br>
            <strong><u>Dados Pessoais</u></strong>
            <br>
            <strong>CPF:</strong> <i>${cpf}</i><br>
            <strong>Nome:</strong> <i>${nome}</i><br>
            <strong>E-mail:</strong> <i>${email}</i><br>
            <strong>Telefone:</strong> <i>${fixo}</i><br>
            <strong>Celular:</strong> <i>${celular}</i><br>
            <br>
            <strong><u>Dados de Endereço</u></strong>
            <br>
            <strong>CEP:</strong> <i>${cep}</i><br>
            <strong>Logradouro:</strong> <i>${rua}</i><br>
            <strong>Número:</strong> <i>${numero}</i><br>
            <strong>Bairro:</strong> <i>${bairro}</i><br>
            <strong>Cidade:</strong> <i>${cidade}</i><br>
            <strong>Estado:</strong> <i>${estado}</i><br>
            <br>
            <strong>Local de abertura:</strong> <i>${local}</i><br>
            <br>
            <strong>Mensagem:</strong> <i>${mensagem}</i>`,
            "text": `Olá ${nome} acaba de preencher o formulario de interesse em ser um franqueado `,
        });

        try {
            await actionEnviarEmail(data)
            setOpen(true);

        } catch (error) {
            console.log(error)

        }   

    }
    
    const [open, setOpen] = React.useState(false);

    // const handleClickOpen = () => {
    //   setOpen(true);
    // };
  
    const handleClose = () => {
      setOpen(false);
    };
    
    function recarregarPagina(){
        window.location.reload();
    } 

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Dados pessoais:
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        onChange={e => setCpf(e.target.value)}
                        value={maskCPF(cpf)}
                        required
                        id="cpf"
                        name="cpf"
                        label="CPF"
                        variant="standard"

                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        onChange={e => setNome(e.target.value)}
                        value={nome}
                        required
                        id="nome"
                        name="nome"
                        label="Nome Completo"
                        fullWidth
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        required
                        id="email"
                        name="email"
                        label="E-mail"
                        fullWidth
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        onChange={e => setFixo(e.target.value)}
                        value={formataNumeroFixo(fixo)}
                        id="fixo"
                        name="fixo"
                        label="Telefone Fixo"
                        fullWidth
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        onChange={e => setCelular(e.target.value)}
                        value={formataNumeroCelular(celular)}
                        required
                        id="celular"
                        name="celular"
                        label="Celular"
                        fullWidth
                        variant="standard"
                    />
                </Grid>
            </Grid>

            <Typography variant="h6" gutterBottom sx={{ mt: 5 }}>
                Dados de endereço:
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <TextField
                        onChange={e => setCep(e.target.value)}
                        value={maskCEP(cep)}
                        required
                        id="cep"
                        name="cep"
                        label="CEP"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        onChange={e => setRua(e.target.value)}
                        value={rua}
                        required
                        id="rua"
                        name="rua"
                        label="Logradouro"
                        fullWidth
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <TextField
                        onChange={e => setNumero(e.target.value)}
                        value={numero}
                        required
                        id="numero"
                        name="numero"
                        label="Número"
                        fullWidth
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        onChange={e => setComplemento(e.target.value)}
                        value={complemento}
                        id="complemento"
                        name="complemento"
                        label="Complemento"
                        fullWidth
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        onChange={e => setBairro(e.target.value)}
                        value={bairro}
                        required
                        id="bairro"
                        name="bairro"
                        label="Bairro"
                        fullWidth
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        onChange={e => setCidade(e.target.value)}
                        value={cidade}
                        required
                        id="cidade"
                        name="cidade"
                        label="Cidade"
                        fullWidth
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        onChange={e => setEstado(e.target.value)}
                        value={estado}
                        required
                        id="estado"
                        name="estado"
                        label="Estado"
                        fullWidth
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        onChange={e => setLocal(e.target.value)}
                        value={local}
                        required
                        id="local"
                        name="local"
                        label="Local de abertura da franquia"
                        fullWidth
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        onChange={e => setMensagem(e.target.value)}
                        value={mensagem}
                        id="mensagem"
                        name="mensagem"
                        label="Deixe uma mensagem pra gente"
                        fullWidth
                        variant="standard"
                    />
                </Grid>

            </Grid>

            <Grid container>
                <Button variant='contained' color='primary' sx={{ mt: 8 }} onClick={onEnviar}>
                    Enviar
                </Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Cadastro realizado com sucesso"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Obrigado por se cadastrar para ser um franqueado Katuxa Calçados!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={recarregarPagina} autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>


        </React.Fragment>

    );

}