export const maskDate = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1");
};

export const maskCPF = value => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};

export const maskCEP = value => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1-$2")
    .replace(/(\d{3})(\d+?$)/, "$1");
};

export const formataNumeroCelular = value => {
  return value

  .replace(/\D/g, '')
  .replace(/(\d{2})(\d)/, '($1)$2')
  .replace(/(\d{4})(\d)/, '$1-$2')
  .replace(/(\d{4})-(\d)(\d{4})/, ' $1$2-$3')
  .replace(/(-\d{4})\d+?$/, '$1')

};

export const formataNumeroFixo = value => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1)$2")
    .replace(/(\d{1})(\d)/, "$1$2")
    .replace(/(\d{4})(\d)/, " $1-$2")
    .replace(/(\d{4})(\d+?$)/, "$1");
};

export const soNumeros = (string) => {
  var numbers = string.replace(/[^0-9]/g, '');
  return numbers;
}